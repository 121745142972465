import React from "react"
import { FooterPageLayout } from "../components/Global/Layouts"

const PrivacyPage = () => (
  <FooterPageLayout
    type={"privacy"}
    title={"Privacy Policy"}
    description={"The privacy policy page :o"}
    query={"query { shop { privacyPolicy { body } } }"}
  />
)

export default PrivacyPage
